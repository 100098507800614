<!-- 
    <h2 class="title">Page not found</h2>
    <div style="container">
    </div> -->

<div class="container">
  <a class="logo-container" [href]="['/']">
    <img src="/assets/images/logo.png" />
  </a>
  <span class="title">
    <h1>Page not found</h1>
  </span>
  <span class="url-back">
    <h3>
      Para volver al inicio haz click
      <a href="/">aquí</a>
    </h3>
  </span>
</div>
