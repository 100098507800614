import { WorkflowService } from '@foxeet/data-access';
import { GenericMap } from '../interfaces/mappers.interfaces';
import { Filter, MobileFilter, WebFilter } from './filter.class';
import { UnsuscriptionHandler } from '../components';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { GenericTableData, OrNull } from '@foxeet/domain';
import { catchError, finalize, map, takeUntil } from 'rxjs/operators';
import { TreeNode } from 'primeng/api';
import { Action, Selector, Store } from '@ngrx/store';
import { Directive, OnDestroy } from '@angular/core';

export interface BaseDataSource<T> extends DataSource<T> {
  entitySubject: Observable<T[]>;
  loadingSubject: Observable<boolean>;

  downloadExcel(body: { [prop: string]: unknown }): void;
}

class ServiceDataSource<T, S extends WorkflowService> extends UnsuscriptionHandler implements BaseDataSource<T> {
  public entitySubject = new BehaviorSubject<T[]>([]);
  public loadingSubject = new BehaviorSubject<boolean>(true);

  constructor(protected _service: S) {
    super();
  }

  updateData(data: T[]) {
    this.entitySubject.next(data);
  }

  public connect(cv: CollectionViewer): Observable<T[]> {
    return this.entitySubject.asObservable();
  }

  public disconnect(cv: CollectionViewer): void {
    this.entitySubject.complete();
    this.loadingSubject.complete();
  }

  downloadExcel(body: { [prop: string]: unknown }) {
    return this._service.getExcelSummary(body);
  }
}

export class PrimengDatasource<T> extends UnsuscriptionHandler {
  public entitySubject: BehaviorSubject<TreeNode<T>[]> = new BehaviorSubject<TreeNode<T>[]>([]);

  // TODO: review to set
  public loadingSubject = new BehaviorSubject<boolean>(true);

  protected totalCount = 0;

  public data: TreeNode<T>[] = [];

  constructor() {
    super();
  }

  loadData(loadFunction: (...args: any) => Observable<any>) {
    this.loadingSubject.next(true);

    loadFunction()
      .pipe(
        takeUntil(this._componentDestroyed),
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false)),
      )
      .subscribe((orders: any) => {
        this.data = orders;
        this.entitySubject.next(orders);
      });
  }

  setData(data: TreeNode<T>[]) {
    this.data = data;
    this.entitySubject.next(data);
  }
}

@Directive()
export class StoreDataSource<T, A extends Action, S extends Selector<any, T[]>, M extends GenericMap, F extends Filter> implements BaseDataSource<T>, OnDestroy {
  private unsub$ = new Subject<void>();
  public entitySubject: Observable<T[]>;
  public loadingSubject = new BehaviorSubject<boolean>(true);

  constructor(private store: Store, private action: A, private selector: S, private mapper: M, private filter: F) {
    this.entitySubject = this.store.select(this.selector).pipe(takeUntil(this.unsub$));
  }

  public connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.entitySubject;
  }

  public disconnect(collectionViewer: CollectionViewer): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  public downloadExcel(body: { [p: string]: unknown }): void {
    return;
  }

  public ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }
}

export class NDataSource<T, S extends WorkflowService, M extends GenericMap, F extends WebFilter> extends ServiceDataSource<T, S> implements BaseDataSource<T> {
  // TODO: Review
  public totalCount = 0;

  public data: T[] = [];

  constructor(protected _service: S, protected _mapper: M, protected _filter: OrNull<F> = null, protected hasExpandedRows: boolean = false) {
    super(_service);
  }

  loadData() {
    const { page = 1, size = 10, data = {} } = this._filter?.getCurrentFilter() ?? {};

    this._componentDestroyed.next('');
    this.loadingSubject.next(true);
    this._service
      .listPageByFilter<GenericTableData<T>>(page, size, data)
      .pipe(
        map((response: GenericTableData<T>) => {
          this.totalCount = response.totalItemsCount;
          return this._mapper.bodyMap(response.items ? response.items : response);
        }),
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false)),
        takeUntil(this._componentDestroyed),
      )
      .subscribe((orders) => {
        this.data = this.hasExpandedRows
          ? orders.reduce(
              (acc: any, curr: any) => [
                ...acc,
                curr,
                {
                  element: curr,
                  detailRow: true,
                },
              ],
              [],
            )
          : orders;
        this.entitySubject.next(this.data);
      });
  }
}

export class MobileNDatasource<T, S extends WorkflowService, F extends MobileFilter> extends ServiceDataSource<T, S> implements BaseDataSource<T> {
  // TODO: Review
  public totalPages: number;

  private _data: T[] = [];
  get data(): T[] {
    return this._data;
  }

  constructor(protected _service: S, protected _filter: OrNull<F> = null) {
    super(_service);
    this.totalPages = 0;
  }

  public itemMapper: ((data: T[]) => any[]) | undefined;

  public loadData(event: OrNull<any> = null) {
    const { page = 1, size = 10, data = {} } = this._filter?.getCurrentFilter() ?? {};
    this.loadingSubject.next(true);
    this._service
      .listPageByFilter<GenericTableData<T> | T[]>(page, size, data)
      .pipe(
        map((response: any) => {
          this.totalPages = response?.totalPages ?? 0;
          return response.items ?? response;
        }),
        finalize(() => {
          this.loadingSubject.next(false);
          if (event) {
            event.target.complete();
          }
        }),
        catchError(() => of([])),
      )
      .subscribe((newData: T[]) => {
        if (newData) {
          this._data.push(...(this.itemMapper ? this.itemMapper(newData) : newData));
          this.entitySubject.next(this._data);
        }
      });
  }

  public clearData(): void {
    this._data = [];
    this.totalPages = 0;
    this.entitySubject.next([]);
  }

  public refreshData(event = null, sameFilterData = false): void {
    this.clearData();
    const defaultFilter = this._filter?.getDefaultFilter();
    if (sameFilterData && defaultFilter) {
      defaultFilter.data = this._filter?.getCurrentFilter().data;
    }
    this._filter?.setCurrentFilter(defaultFilter ?? {});
    this.loadData(event);
  }
}
