import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { DynamicConfigLoaderService } from '../../config-loader/config-loader.service';
import { ErrorDialogService } from './error-dialog.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private zone: NgZone, private _errorDialogService: ErrorDialogService, private _dynamicConfigLoaderService: DynamicConfigLoaderService) {}

  handleError(error: any) {
    // Check if it's an error from an HTTP response
    if (!(error instanceof HttpErrorResponse)) {
      if (error.rejection) {
        error = error.rejection;
      }
    }

    if (!this._dynamicConfigLoaderService.Options.environment.production) {
      if (!this.isExceptedError(error?.code)) {
        //this.zone.run(() => this._errorDialogService.openDialog(error?.message || 'Undefined client error'));
      } else {
        console.error('Excepted error');
      }
    }

    console.error('Error from global error handler', error);
  }

  isExceptedError(errorCode: string): boolean {
    switch (errorCode) {
      case '100':
        return true;
      default:
        return false;
    }
  }
}
