import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PATHS } from '@foxeet/n-core';
import {
  AccountCanLoad,
  AuthGuardService,
  ChangeTemporalPasswordComponent,
  ChangeTemporalPasswordGuardService,
  LoginComponent,
  PasswordRestoreComponent,
  RequirePasswordChangeComponent,
} from '@foxeet/feature/auth';
import { ExternalConnectionsComponent } from '@foxeet/feature/external-connections';
import { PageNotFoundComponent } from '@foxeet/ui';

const routes: Routes = [
  {
    path: `${PATHS.LOGIN}`,
    component: LoginComponent,
  },
  {
    path: `${PATHS.CHANGE_TEMPORAL_PASSWORD}`,
    component: ChangeTemporalPasswordComponent,
    canActivate: [ChangeTemporalPasswordGuardService],
  },
  {
    path: `${PATHS.REQUIRE_PASSWORD_CHANGE}`,
    component: RequirePasswordChangeComponent,
  },
  {
    path: `${PATHS.PASSWORD_RESTORE}`,
    component: PasswordRestoreComponent,
  },
  {
    path: `external-connections`,
    data: { moduleId: null },
    children: [
      {
        path: '',
        loadChildren: () => import('@foxeet/feature/external-connections').then((module) => module.ExternalConnectionsModule),
        data: { moduleId: null },
      },
    ],
    component: ExternalConnectionsComponent,
  },
  {
    path: '',
    loadChildren: () => import('./modules/modules.module').then((module) => module.ModulesModule),
    canLoad: [AccountCanLoad],
    canActivate: [AuthGuardService],
    data: { platform: 'ivforce' },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
