import { Component } from '@angular/core';
import { Router, ChildActivationStart } from '@angular/router';
import { PATHS } from '@foxeet/n-core';

@Component({
  selector: 'ivf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private childsToExclude = ['buildingElementTempId'];

  constructor(private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof ChildActivationStart) {
        if (Object.keys(event.snapshot.params).length > 0) {
          Object.keys(event.snapshot.params).forEach((param) => {
            if (!this.childsToExclude.includes(param)) {
              const paramValue = event.snapshot.params[param];
              const valueParsed: number = parseInt(paramValue);
              isNaN(valueParsed) ? this.router.navigateByUrl(PATHS.PAGE_NOT_FOUND) : '';
            }
          });
        }
      }
    });
  }
}
