import { EventEmitter, Injectable } from '@angular/core';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DynamicConfigLoaderService } from '@foxeet/utils-modules';
import { AuthService } from './auth.service';

export type SignalrServiceEvent = {
  error?: Error;
  message: string;
};

@Injectable({ providedIn: 'root' })
export class SignalrService extends UnsuscriptionHandler {
  private hubConnection?: signalR.HubConnection;
  private hubConnectionRetries = 0;

  public connected$ = new BehaviorSubject<boolean>(false);
  public onEventsMap: Map<string, Subject<any>> = new Map();

  private readonly joinRoomEvent = new EventEmitter<SignalrServiceEvent>(true);
  private readonly leaveRoomEvent = new EventEmitter<SignalrServiceEvent>(true);
  private readonly connectionEvent = new EventEmitter<SignalrServiceEvent>(true);

  get onJoinRoomEvent() {
    return this.joinRoomEvent;
  }

  get onLeaveRoomEvent() {
    return this.leaveRoomEvent;
  }

  get onConnectionEvent() {
    return this.connectionEvent;
  }

  constructor(private _authService: AuthService, private _dynamicConfigLoaderService: DynamicConfigLoaderService) {
    super();
    this._authService.isAuthenticated() && !this.hubConnection ? this.connect() : this.disconnect();
  }

  public joinRoom(backendMethod: string, resourceId: number): void {
    if (this._authService.isAuthenticated() && !this.hubConnection) this.connect();
    this.connected$.pipe(takeUntil(this._componentDestroyed)).subscribe((connected) => {
      if (connected) {
        this.onEventsMap.set(`${backendMethod}_${resourceId}`, new Subject());
        this.hubConnection
          ?.invoke(backendMethod, resourceId)
          .then(() => this.joinRoomEvent.emit({ message: `Join room ${backendMethod} with resourceId ${resourceId} successfully` }))
          .catch((error) => this.joinRoomEvent.emit({ message: 'Join room error', error }));
      }
    });
  }

  public leaveRoom(backendMethod: string, resourceId: number): void {
    this.hubConnection
      ?.invoke(backendMethod, resourceId)
      .then(() => this.leaveRoomEvent.emit({ message: `Leave room ${backendMethod} with resourceId ${resourceId} succesfully` }))
      .catch((error) => this.leaveRoomEvent.emit({ message: 'Join room error', error }));
  }

  observeEvents(eventId: string, id: string) {
    this.hubConnection?.on(eventId, (...res) => {
      this.onEventsMap.get(id)?.next(res);
    });
    return this.onEventsMap.get(id);
  }

  cancelObserveEnvents(eventId: string) {
    this.hubConnection?.off(eventId);
    this.onEventsMap.delete(eventId);
  }

  public connect(): void {
    const environment = this._dynamicConfigLoaderService.Options.environment;
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.defaultEndpoint}/foxeethub`, { accessTokenFactory: () => this._authService.getToken() ?? '' })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => {
          if (this.hubConnectionRetries <= 3) {
            this.hubConnectionRetries++;
            this.connectionEvent.emit({ message: `Trying reconnection... ${this.hubConnectionRetries}` });
            return 3000;
          }
          return null;
        },
      })
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this.connectionEvent.emit({ message: 'connected!!' });
        this.hubConnectionRetries = 0;
        this.connected$.next(true);
      })
      .catch((err) => console.log(err));
  }

  public disconnect(): void {
    if (this.hubConnection) this.hubConnection.stop();
  }
}
