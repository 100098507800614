<ui-basic-container [title]="data?.title || 'confirm' | translate">
  <ng-container>
    <div class="d-flex">
      <p *ngIf="!data?.allowFormat">{{ data?.message | translate }}</p>
      <pre *ngIf="data?.allowFormat" style="font-family: inherit; white-space: pre-line">{{ data?.message | translate }}</pre>
    </div>
    <div class="d-flex" *ngIf="hasUserInput">
      <ui-form-input-text
        [form]="formGroup"
        [controlName]="'userMessage'"
        [label]="inputPlaceholder"
        componentId="AT_confirm_modal_userMessage"
        [type]="'text'"
      ></ui-form-input-text>
    </div>
  </ng-container>
  <div class="d-flex justify-content-around mt-5">
    <ui-async-button
      *ngIf="showClose"
      class="mr-3"
      [mat-dialog-close]="false"
      size="md"
      [label]="'close' | translate"
      [asyncButton]="false"
      [buttonClassByType]="'secondary'"
      componentId="AT_close_modal"
    >
    </ui-async-button>

    <ui-async-button
      *ngIf="showCancel"
      class="mr-3"
      componentId="AT_cancel_modal"
      [mat-dialog-close]="false"
      size="md"
      [label]="closeLabel | translate"
      [asyncButton]="false"
      [buttonClassByType]="'secondary'"
    >
    </ui-async-button>

    <ui-async-button
      *ngIf="showConfirm"
      size="md"
      componentId="AT_confirm_modal"
      [label]="okLabel | translate"
      [loadingLabel]="okLoadingLabel | translate"
      [asyncButton]="true"
      [inputState]="btnState"
      (clickEvent)="confirm()"
    >
    </ui-async-button>
  </div>
</ui-basic-container>
