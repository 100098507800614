import { Component, OnDestroy } from '@angular/core';
import { BreadcrumService } from '@foxeet/ui';
import { isNil } from 'lodash';
import { combineLatest } from 'rxjs';
import { ExternalConnectionsService } from './providers/external-connections.service';
import { isNilOrEmptyString } from '@foxeet/utils/functions';
import { PATHS } from '@foxeet/n-core';

@Component({
  selector: 'foxeet-external-connections',
  templateUrl: './external-connections.component.html',
  styleUrls: ['./external-connections.component.scss'],
})
export class ExternalConnectionsComponent implements OnDestroy {
  logoUrl: string;

  _externalConnection;
  baseUrl = '';
  queryParams;

  constructor(private _breadcrumService: BreadcrumService, private _externalConnectionsService: ExternalConnectionsService) {
    combineLatest([this._externalConnectionsService.externalConnection$, this._externalConnectionsService.externalConnectionData$]).subscribe(
      ([externalConnection, externalConnectionData]) => {
        if (!isNilOrEmptyString(externalConnection) && !isNil(externalConnectionData) && this._externalConnection !== externalConnection) {
          this._externalConnection = externalConnection;
          this.baseUrl = `/${PATHS.EXTERNAL_CONNECTIONS}/${this._externalConnection.toLocaleLowerCase()}`;
          this.queryParams = externalConnectionData;

          this._breadcrumService.addItem(
            this._externalConnection,
            this._externalConnection,
            `/${PATHS.EXTERNAL_CONNECTIONS}/${this._externalConnection.toLocaleLowerCase()}`,
            0,
            externalConnectionData,
          );

          this.logoUrl = this._externalConnectionsService.getLogoUrl(externalConnection.toLocaleLowerCase());
        }
      },
    );
  }

  ngOnDestroy(): void {
    this._breadcrumService.removeItem(this._externalConnection);
  }
}
