import { TableColumn, TableConfig } from '../../../../../components/generic-tables/domain/table.model';

import {
  columnExecutionToolIdAppraisalList,
  columnAddressAppraisalList,
  columnExternalRecordIdAppraisalList,
  columnLocalityAppraisalList,
  columnProvinceAppraisalList,
  columnRecordIdAppraisalList,
  columnAddedDateTimeAppraisalList,
  columnCustomerAppraisalList,
  columnCurrentStepValueAppraisalList,
} from '../../../appraisal-list/configs/appraisal-list-column-definition';

/**
 * @TODO: REFACTOR to utils. look for same function
 */
const getDisplayedColumns = (columnConfig: TableColumn[]): string[] => {
  return columnConfig.map((el) => el.columnId);
};

export const appraisalColumns = [
  columnRecordIdAppraisalList,
  columnExecutionToolIdAppraisalList,
  columnExternalRecordIdAppraisalList,
  columnAddressAppraisalList,
  columnLocalityAppraisalList,
  columnProvinceAppraisalList,
  columnAddedDateTimeAppraisalList,
  columnCustomerAppraisalList,
  columnCurrentStepValueAppraisalList,
];

export const externalConnectionsUnicajaAppraisalListTableConfig: TableConfig = {
  topButtonActions: [],
  downloadExcelEnabled: false,
  displayedColumns: getDisplayedColumns(appraisalColumns),
  columns: appraisalColumns,
};
