import { FilterInputDatepickerTypes, FilterInputTypes, IFormDataModel } from '@foxeet/domain';
import { BehaviorSubject } from 'rxjs';
import { AppraisalPurposes } from '@foxeet/domain';
import { FormUtils } from '@foxeet/utils/functions';

export const externalConnectionsUnicajaAppraisalListFilterConfig = (customerFunc): IFormDataModel[][] => [
  [
    {
      id: `recordId`,
      controlName: 'recordIdContains',
      labelCode: 'APPRAISALS_fileNumber',
      backendId: 'recordIdContains',
      type: FilterInputTypes.TEXT,
      isHidden: false,
      isDisabled: false,
      size: { xs: 12, md: 4 },
    },
    {
      id: 'customerIds',
      controlName: 'customerIds',
      backendId: 'customerIds',
      labelCode: 'APPRAISALS_REPORT_dni',
      type: FilterInputTypes.CHIPS_SEARCHER,
      minimumCharsToEmitEvent: 3,
      listSubject: customerFunc,
      filterName: 'nationalIdentityDocumentContains',
      labelKeys: ['fullName'],
      valueKey: 'id',
      size: { xs: 4 },
    },
    {
      id: 'customerIds',
      controlName: 'customerIds',
      backendId: 'customerIds',
      labelCode: 'APPRAISALS_customer',
      type: FilterInputTypes.CHIPS_SEARCHER,
      minimumCharsToEmitEvent: 3,
      listSubject: customerFunc,
      filterName: 'fullNameContains',
      labelKeys: ['fullName'],
      valueKey: 'id',
      size: { xs: 4 },
    },
  ],
  [
    {
      id: 'addedMinDateTime',
      controlName: 'addedMinDateTime',
      backendId: 'addedMinDateTime',
      labelCode: 'APPRAISALS_creationDate',
      subLabelCode: 'from',
      type: FilterInputTypes.DATE_PICKER,
      datePickerTarget: FilterInputDatepickerTypes.FROM.toLowerCase(),
      isHidden: false,
      isDisabled: false,
      size: { xs: 12, md: 4 },
    },
    {
      id: 'addedMaxDateTime',
      controlName: 'addedMaxDateTime',
      backendId: 'addedMaxDateTime',
      labelCode: 'APPRAISALS_creationDate',
      subLabelCode: 'toDate',
      type: FilterInputTypes.DATE_PICKER,
      datePickerTarget: FilterInputDatepickerTypes.TO.toLowerCase(),
      isHidden: false,
      isDisabled: false,
      setTimeToEndOfDay: true,
      size: { xs: 12, md: 4 },
    },
    {
      id: 'purposes',
      controlName: 'purposes',
      backendId: 'purposes',
      labelCode: 'APPRAISALS_purpose',
      type: FilterInputTypes.MULTISELECT,
      appendToBody: true,
      indexBy: 'value',
      labelKeys: ['label'],
      isHidden: false,
      isDisabled: false,
      options: new BehaviorSubject(FormUtils.toPrimeNgDropdownValue(AppraisalPurposes, 'AppraisalPurposes')),
      size: { xs: 4 },
    },
  ],
];
